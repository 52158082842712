<template>
  <div>
    <v-app id="inspire">
      <div style="margin-top: 20px; margin-left: 35px" class="slot_calendar">
        <v-row>
          <v-col>
            <v-autocomplete solo item-value="name" item-text="doctor_name" return-object required
              v-model="selected_partner" @change="selected_partner_change" :items="partner_list" label="Select Partner"
              outlined dense clearable>
              <template slot="selection" slot-scope="data">
                <!-- HTML that describe how select should render selected items -->
                <small>{{ data.item.doctor_name }} - {{ data.item.name }}</small>
              </template>
              <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                <small>{{ data.item.doctor_name }} - {{ data.item.name }}</small>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-menu v-model="from_date_menu" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="computed_from_date" label="From Date" prepend-inner-icon="mdi-calendar" readonly
                  outlined dense v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="from_date" @input="from_date_menu = false"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu v-model="to_date_menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
              offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="computed_to_date" label="To Date" prepend-inner-icon="mdi-calendar" readonly
                  outlined dense v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="to_date" @input="to_date_menu = false"></v-date-picker>
            </v-menu>
          </v-col>

          <v-col>
            <v-btn :disabled="to_date === null || from_date === null" class="ml-2 mr-2 secondary"
              @click="get_ledger(false)">Submit</v-btn>
            <!-- <v-btn :disabled="
              to_date === null ||
              from_date === null" @click="get_ledger(true)">Download</v-btn> -->
          </v-col>
        </v-row>

        <v-col cols="12" sm="12" md="12" style="margin-left: -25px">
          <v-card>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" v-for="(item, index) in transaction_fields" :key="index" :style="item == 'Date' || item == 'Debit' || item == 'Credit'
                        ? { width: '7%' }
                        : item == 'Balance(Dr-Cr)'
                          ? { width: '9%' }
                          : item == 'Ref'
                            ? { width: '12%' }
                            : {}
                      ">
                      {{ item }}
                    </th>
                  </tr>
                </thead>
                <tbody v-if="transaction_items.length">
                  <tr v-for="(item, index) in transaction_items" :key="index">
                    <td>{{ formatDate(item.posting_date) }}</td>
                    <td>
                      {{ item.voucher_type }}
                      <br />
                      {{ item.voucher_no }}
                    </td>
                    <td>
                      Against: {{ item.against }}
                      <br />
                      Remarks: {{ item.remarks }}
                    </td>
                    <td>{{ item.debit }}</td>
                    <td>{{ item.credit }}</td>
                    <td>{{ item.balance }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <p class="no-data" v-if="!transaction_items.length && !isApiCalling">
              No data found
            </p>
          </v-card>
        </v-col>
      </div>
    </v-app>
  </div>
</template>
        
<script>
import CommonApi from "@/services/CommonApi";
import axios from "axios";

export default {
  name: "TransactionS",
  data() {
    return {
      from_date_menu: false,
      to_date_menu: false,
      from_date: "",
      to_date: "",
      isApiCalling: true,
      transaction_fields: [
        "Date",
        "Ref",
        "Remarks",
        "Debit",
        "Credit",
        "Balance(Dr-Cr)",
      ],
      transaction_items: [],
      validate_surgeon: {
        select1: [
          (v) => Boolean(Object.keys(v || {})[0]) || "Field is required",
        ],
      },
      partner_list: [],
      selected_partner: null,
    };
  },
  computed: {
    computed_from_date() {
      return this.formatDate(this.from_date);
    },
    computed_to_date() {
      return this.formatDate(this.to_date);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    // Get Get Partner list
    async get_partner_list() {
      await CommonApi.post_data("get_partner_list_0029").then((res) => {
        if (res.data.length && res.status == 200) {
          this.partner_list = res.data;
        }
      });
    },
    convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    },
    /* Get ledger Details API */
    async get_ledger(isDownload) {
      var data = new FormData();
      data.append("from_date", this.convert(this.from_date));
      data.append("to_date", this.convert(this.to_date));
      data.append(
        "partner",
        this.selected_partner ? this.selected_partner.name : ""
      );
      // data.append("download", isDownload);

      if (!isDownload) {
        await CommonApi.post_data("get_transactions_0019", data).then((res) => {
          this.transaction_items = [];
          if (res && res.data.data.length) {
            this.transaction_items = res.data.data;
            this.isApiCalling = false;
          }
        });
      } else {
        this.$toast.success("Dowloading Ledger...", {
          position: "bottom-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        axios.defaults.headers.common["Authorization"] =
          "token " + CommonApi.api_key + ":" + CommonApi.api_secret;
        axios({
          url:
            CommonApi.base_url + CommonApi.base_path + "get_transactions_0019",
          data,
          method: "POST",
          responseType: "blob", // <= important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "ledger.pdf");
          document.body.appendChild(link);
          link.click();
        });
      }
    },
    selected_partner_change(value) {
      localStorage.setItem("selected_partner", JSON.stringify(value));
      localStorage.setItem("selected_surgeon", null);
    },
  },
  mounted() {
    this.selected_partner = localStorage.getItem("selected_partner")
      ? JSON.parse(localStorage.getItem("selected_partner"))
      : "";

    let date = new Date();
    this.to_date = date.toISOString().split("T")[0];
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    this.from_date = this.convert(firstDay);
    this.get_ledger(false);
    this.get_partner_list();
  },
};
</script>