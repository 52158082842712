<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app color="primary" dark>
      <v-sheet class="avatar-image center pb-7 pt-8" color="white" dark>
        <v-img src="../assets/logo.png" max-width="170"></v-img>
      </v-sheet>

      <v-divider></v-divider>

      <!-- <v-list>
        <v-list-item
          v-for="(item, index) in sidebar_menu"
          :key="index"
          link
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.menu }}</v-list-item-title>
          </v-list-item-content>
          <v-list-group
          :value="true"
          prepend-icon="mdi-account-circle"
        >
          <template v-slot:activator>
            <v-list-item-title>Users</v-list-item-title>
          </template>
  
          <v-list-group
            :value="true"
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Admin</v-list-item-title>
              </v-list-item-content>
            </template>
  
            <v-list-item
              v-for="([title, icon], i) in admins"
              :key="i"
              link
            >
              <v-list-item-title v-text="title"></v-list-item-title>
  
              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
  
          <v-list-group
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Actions</v-list-item-title>
              </v-list-item-content>
            </template>
  
            <v-list-item
              v-for="([title, icon], i) in cruds"
              :key="i"
              link
            >
              <v-list-item-title v-text="title"></v-list-item-title>
  
              <v-list-item-icon>
                <v-icon v-text="icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </v-list-group>
        </v-list-item>
      </v-list> -->

      <v-list class="ma-0 pa-0" color="primary" dark>
        <div v-for="(item, index) in sidebar_menu" :key="index">
          <v-list-item v-if="item.path" link :to="item.path">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ item.menu }}</v-list-item-title>
          </v-list-item>
          <!-- :prepend-icon="item.icon" -->
          <v-list-group :value="false" v-if="!item.path">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.menu }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="(sub_item, index) in item.sub_menu"
              :key="index"
              link
              :to="sub_item.path"
            >
              <v-list-item-icon>
                <v-icon v-text="sub_item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="sub_item.menu"></v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-system-bar app v-if="app_mode == 'training'"></v-system-bar>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ currentPath.replace("-", " ") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items right color="">
        <v-menu bottom right offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text>
              Account
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>

          <v-list class="grey lighten-3">
            <v-list-item
              v-for="(item, index) in header_menu"
              :key="index"
              link
              :to="item.path"
              @click="item.menu === 'Logout' ? logout() : ''"
            >
              {{ item.menu }}
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <v-main class="page-content">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "MainBody",
  data: () => ({
    drawer: null,
    app_mode: process.env.VUE_APP_MODE,
    sidebar_menu: [
      {
        menu: "Dashboard",
        icon: "mdi-view-dashboard",
        path: "/dashboard",
      },
      {
        menu: "Slot Calendar",
        icon: "mdi-calendar-blank-outline",
        path: "/slot-calendar",
      },
      {
        menu: "Surgery Calendar",
        icon: "mdi-calendar-blank-outline",
        path: "/surgery-calendar",
      },
      {
        menu: "Surgeries",
        icon: "mdi-hospital",
        sub_menu: [
          {
            menu: "View All Surgery",
            icon: "mdi-format-list-bulleted",
            path: "/surgery-list",
          },
        ],
      },
      {
        menu: "Patients",
        icon: "mdi-account",
        sub_menu: [
          {
            menu: "New Patient",
            icon: "mdi-medical-bag",
            path: "/new-patient",
          },
          {
            menu: "View All Patient",
            icon: "mdi-format-list-bulleted",
            path: "/patient-list",
          },
        ],
      },
      {
        menu: "Transactions",
        icon: "mdi-briefcase-variant-outline",
        path: "/transactions",
      },
      {
        menu: "Availability",
        icon: "mdi-calendar-check",
        // path: "mdi-setting",
        sub_menu: [
          {
            menu: "Availability Calender",
            icon: "mdi-calendar-blank-outline",
            path: "/availability-calendar",
          },
          {
            menu: "Create Availability",
            icon: "mdi-plus",
            path: "/create-availability",
          },
        ],
      },
      {
        menu: "Manage Profile",
        icon: "mdi-account-group-outline",
        path: "/manage-profile",
      },
    ],
    header_menu: [
      {
        menu: "Manage Profile",
        path: "/manage-profile",
      },
      {
        menu: "Logout",
        path: "/login",
      },
    ],
  }),
  computed: {
    currentPath() {
      return this.$route.name;
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("userInfo");
      this.$router.push("/login").catch((error) => {
        console.info(error.message);
      });
    },
  },
};
</script>

<style scoped>
.v-toolbar__title {
  overflow: visible;
}
.avatar-image {
  display: flex;
  justify-content: center;
}
</style>
