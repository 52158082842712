<template>
  <div>
    <router-view />
    <Loader />
  </div>
</template>

<script>
import Loader from "./components/Loader.vue";

export default {
  name: "App",
  components: { Loader },
};
</script>
