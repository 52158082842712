import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

/* Import Vue Package */
import VueSweetalert2 from "vue-sweetalert2";
import Toasted from "vue-toasted";
import Toast from "vue-toastification";

/* Use Vue Package */
Vue.use(VueSweetalert2);
Vue.config.productionTip = false;

Vue.use(Toasted);
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});

/* Import Theme scss */
import "./assets/scss/app.scss";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-toastification/dist/index.css";

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
