import axios from "axios";

export default {

  // Training 1
  // base_url: "https://leotrn.rsasurgicalcentre.co.uk/api/method/",
  // base_url_for_Image: "https://leotrn.rsasurgicalcentre.co.uk/",

  // Development
  // base_url: "https://leodev.rsasurgicalcentre.co.uk/api/method/",
  // base_url_for_Image: "https://leodev.rsasurgicalcentre.co.uk/",

  // Production
  // base_url: "https://leoprd.rsasurgicalcentre.co.uk/api/method/",
  // base_url_for_Image: "https://leoprd.rsasurgicalcentre.co.uk/",


  base_url: process.env.VUE_APP_BASE_URL,
  base_url_for_Image: process.env.VUE_APP_BASE_URL_IMAGE,

  base_path: "leo.api.v2.tm.",
  api_key: "",
  api_secret: "",

  /* Get API Call */
  get_data(url, params = "") {
    this.validate_key();
    let requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    if (this.api_key !== null || this.api_secret !== null) {
      requestOptions.headers["Authorization"] =
        "token " + this.api_key + ":" + this.api_secret;
    }

    return axios
      .get(this.base_url + this.base_path + url + "?" + params, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        } else {
          return alert(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status == "403" || error.response.status == "401") {
          this.unauthorize_login();
        } else {
          return error.response.data.message;
        }
      });
  },

  /* Post API Call */
  post_data(url, params = "", guest_call = false) {
    let requestOptions = "";
    requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (!guest_call) {
      this.validate_key();
      requestOptions.headers["Authorization"] =
        "token " + this.api_key + ":" + this.api_secret;
    }
    return axios
      .post(this.base_url + this.base_path + url, params, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          response.data.status = response.status
          return response.data;
        } else {
          return alert(response.data.message);
        }
      })
      .catch((error) => {
        // if (error.response) {
        //   return error.response.data.message;
        // }
        error.response.data.status = error.response.status
        if (error.response.status == "500" && url !== "change_password_0022") {
          return error.response.data;
        } if (error.response.status == "500" && url === "change_password_0022") {
          return error.response;
        } if (error.response.status == "403" || error.response.status == "401") {
          this.unauthorize_login();
        } else {
          return error.response.data.message;
        }
      });
  },

  /* Get Token */
  validate_key() {
    this.api_key = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo")).api_key
      : null;
    this.api_secret = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo")).api_secret
      : null;

    if (this.api_secret == "" || this.api_key == "") {
      this.unauthorize_login();
    }
  },

  /* Remove Token */
  unauthorize_login() {
    localStorage.removeItem("userInfo");
    location.reload();
  },

  /* Get JSON */
  get_json_data(json) {
    return require("@/data/" + json);
  },
};
