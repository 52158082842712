<template>
  <v-container class="blue_font_container">
    <v-row>
      <v-col>
        <!-- item-text="doctor_name" -->
        <v-autocomplete solo item-text="doctor_name" item-value="name" return-object required v-model="selected_partner"
          :items="partner_list" label="Select Partner" @change="selected_partner_change" outlined dense>
          <template slot="selection" slot-scope="data">
            <!-- HTML that describe how select should render selected items -->
            {{ data.item.doctor_name }} - {{ data.item.name }}
          </template>
          <template slot="item" slot-scope="data">
            <!-- HTML that describe how select should render items when the select is open -->
            {{ data.item.doctor_name }} - {{ data.item.name }}
          </template>
        </v-autocomplete>
        <!-- item-text="doctor_name" -->
      </v-col>
      <v-col>
        <v-autocomplete solo item-value="name" item-text="doctor_name" return-object required v-model="selected_surgeon"
          @change="selected_surgeon_change" :items="surgeon_items" label="Select Surgeon" outlined clearable dense>
          <template slot="selection" slot-scope="data">
            <!-- HTML that describe how select should render selected items -->
            {{ data.item.doctor_name }} - {{ data.item.name }}
          </template>
          <template slot="item" slot-scope="data">
            <!-- HTML that describe how select should render items when the select is open -->
            {{ data.item.doctor_name }} - {{ data.item.name }}
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4" xl="4">
        <v-card>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h6 font-weight-bold">Today Surgery Count</v-list-item-title>
              <v-list-item-subtitle class="text-h6 font-weight-regular">{{
                today_surgery_count
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" xl="4">
        <v-card>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h6 font-weight-bold">Patient Count</v-list-item-title>
              <v-list-item-subtitle class="text-h6 font-weight-regular">{{
                patient_count
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" xl="4">
        <v-card>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h6 font-weight-bold">Surgery Count</v-list-item-title>
              <v-list-item-subtitle class="text-h6 font-weight-regular">{{
                total_surgery_count
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6" md="4" xl="4">
        <v-card>
          <v-card-title class="text-h6 font-weight-bold">Upcoming Surgery List</v-card-title>
          <div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" v-for="(item, index) in upcoming_surgery_fields" :key="index">
                      {{ item }}
                    </th>
                  </tr>
                </thead>
                <tbody v-if="upcoming_surgery_items.length">
                  <tr v-for="(item, index) in upcoming_surgery_items" :key="index">
                    <td>{{ formatDate(item.date) }}</td>
                    <td>{{ item.patient_name }}</td>
                    <td>{{ item.procedure }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <p class="no-data" v-if="!upcoming_surgery_items.length && !isApiCalling">
              No data found
            </p>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" xl="4">
        <v-card>
          <v-card-title class="text-h6 font-weight-bold">Completed Surgery List</v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" v-for="(item, index) in completed_surgery_fields" :key="index">
                    {{ item }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="completed_surgery_items.length">
                <tr v-for="(item, index) in completed_surgery_items" :key="index">
                  <td>{{ formatDate(item.date) }}</td>
                  <td>{{ item.patient_name }}</td>
                  <td>{{ item.procedure }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <p class="no-data" v-if="!completed_surgery_items.length && !isApiCalling">
            No data found
          </p>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4" xl="4">
        <v-card>
          <v-card-title class="text-h6 font-weight-bold">Invoices</v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left blue_font" v-for="(item, index) in invoices_fields" :key="index">
                    {{ item }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="invoices_items.length">
                <tr v-for="(item, index) in invoices_items" :key="index">
                  <td>{{ item.name }}</td>
                  <td>{{ item.status }}</td>
                  <td>{{ item.grand_total }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <p class="no-data" v-if="!invoices_items.length && !isApiCalling">
            No data found
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
    
<script>
import CommonApi from "@/services/CommonApi";

export default {
  name: "DashBoard",
  data() {
    return {
      today_surgery_count: 0,
      patient_count: 0,
      total_surgery_count: 0,
      upcoming_surgery_fields: ["Date", "Patient", "Procedure"],
      completed_surgery_fields: ["Date", "Patient", "Procedure"],
      upcoming_surgery_items: [],
      completed_surgery_items: [],
      invoices_fields: ["Invoice Id", "Status", "Total"],
      invoices_items: [],
      isApiCalling: true,
      surgeon_items: [],
      selected_surgeon: null,
      partner_list: [],
      selected_partner: null,
      validate_surgeon: {
        select1: [
          (v) => Boolean(Object.keys(v || {})[0]) || "Field is required",
        ],
      },
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    // Get Dashboard Details
    async get_dashboard_details() {
      var data = {
        partner: this.selected_partner ? this.selected_partner.name : "",
        surgeon: this.selected_surgeon ? this.selected_surgeon.name : "",
      };
      await CommonApi.post_data("get_dashboard_details_0021", data).then(
        (res) => {
          if (res && res.data) {
            this.today_surgery_count = res.data.today_surgery;
            this.patient_count = res.data.patient;
            this.total_surgery_count = res.data.total_surgery;
            this.upcoming_surgery_items = res.data.upcoming_surgery;
            this.completed_surgery_items = res.data.completed_surgery;
            this.invoices_items = res.data.invoices;
            this.isApiCalling = false;
          }
        }
      );
    },
    // Get Get Surgeon list
    async get_surgeon_list() {
      var data = {
        partner: this.selected_partner ? this.selected_partner.name : "",
      };
      await CommonApi.post_data("get_surgeon_list_0009", data).then((res) => {
        if (res.data.length && res.status == 200) {
          this.surgeon_items = res.data;
        } else {
          this.surgeon_items = [];
        }
      });
    },
    // Get Get Partner list
    async get_partner_list() {
      await CommonApi.post_data("get_partner_list_0029").then((res) => {
        if (res.data.length && res.status == 200) {
          this.partner_list = res.data;
        }
      });
    },
    selected_partner_change(value) {
      this.selected_surgeon = "";
      this.get_surgeon_list();
      this.get_dashboard_details();
      localStorage.setItem("selected_partner", JSON.stringify(value));
      localStorage.setItem("selected_surgeon", null);
    },
    selected_surgeon_change(value) {
      this.get_dashboard_details();
      localStorage.setItem("selected_surgeon", JSON.stringify(value));
    },
  },
  mounted() {
    this.selected_partner = localStorage.getItem("selected_partner")
      ? JSON.parse(localStorage.getItem("selected_partner"))
      : "";
    this.selected_surgeon = localStorage.getItem("selected_surgeon")
      ? JSON.parse(localStorage.getItem("selected_surgeon"))
      : "";
    this.get_dashboard_details();
    this.get_partner_list();
    if (this.selected_partner) {
      this.get_surgeon_list();
    }
  },
  watch: {
    // selected_partner(value) {
    //   this.get_surgeon_list();
    //   this.get_dashboard_details();
    //   localStorage.setItem("selected_partner", JSON.stringify(value));
    // },
    // selected_surgeon(value) {
    //   this.get_dashboard_details();
    //   localStorage.setItem("selected_surgeon", JSON.stringify(value));
    // },
  },
};
</script>