<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-card flat>
          <v-form ref="availability_form" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-autocomplete solo item-value="name" item-text="doctor_name" return-object required
                    :rules="validate_surgeon.select1" v-model="selected_partner" :items="partner_list"
                    label="Select Partner" outlined clearable hide-details="" @change="selected_partner_change" dense>
                    <template slot="selection" slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      {{ data.item.doctor_name }} - {{ data.item.name }}
                    </template>
                    <template slot="item" slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ data.item.doctor_name }} - {{ data.item.name }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-autocomplete solo item-value="name" item-text="doctor_name" return-object required
                    v-model="selected_surgeon" @change="set_doctor()" :items="surgeon_items" label="Select Surgeon"
                    :rules="validate_surgeon.select1" hide-details="" outlined clearable dense>
                    <template slot="selection" slot-scope="data">
                      <!-- HTML that describe how select should render selected items -->
                      <small>{{ data.item.doctor_name }} - {{ data.item.name }}
                      </small>
                    </template>
                    <template slot="item" slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      <small>{{ data.item.doctor_name }} -
                        {{ data.item.name }}</small>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-menu v-model="menu_from_date" :close-on-content-click="false" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="computed_from_date" label="From Date" readonly v-bind="attrs" outlined
                        hide-details="true" hint="Availability from date" :rules="rules.required" required dense
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="availability.from_date" no-title :max="availability.to_date"
                      @input="menu_from_date = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-menu v-model="menu_to_date" :close-on-content-click="false" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="computed_to_date" label="To Date" readonly v-bind="attrs" outlined dense
                        :rules="rules.required" required hide-details="true" hint="Availability to date"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="availability.to_date" no-title :min="availability.from_date"
                      @input="menu_to_date = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6">
                  <v-select required v-model="availability.from_time" :items="time_range" label="From Time" outlined
                    :rules="rules.required_list" hide-details="true" dense>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select required v-model="availability.to_time" :items="time_range" label="To Time"
                    :rules="rules.required_list" outlined dense hide-details="true">
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-card class="mx-auto" outlined elevation="0">
                    <v-toolbar flat dense>
                      <v-toolbar-title tag="span" style="font-weight: 400; font-size: 16px">
                        Exclude Date Range
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-menu ref="menu_exclude_dates" v-model="menu_exclude_dates" :close-on-content-click="false"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <v-date-picker v-model="exclude_dates" no-title range>
                          <v-spacer></v-spacer>
                          <v-btn text @click="
                            menu_exclude_dates = false;
                          exclude_dates = [];
                          ">
                            Cancel
                          </v-btn>
                          <v-btn text @click="
                                                        var to_date = '';
                            if (exclude_dates.length != 2) {
                              to_date = exclude_dates[0];
                            } else {
                              to_date = exclude_dates[1];
                            }
                            menu_exclude_dates = false;
                            availability.availability_tool_exclude_date.push({
                              from_date: exclude_dates[0],
                              to_date: to_date,
                            });
                            exclude_dates = [];
                            ">
                            Add
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-toolbar>
                    <v-card-text class="pa-1">
                      <v-chip v-for="(
                          date, index
                        ) in availability.availability_tool_exclude_date" :key="index" dense close
                        close-icon="mdi-delete" @click:close="
                            availability.availability_tool_exclude_date.splice(
                              index,
                              1
                            )
                            " class="ma-1">{{ formatDate(date.from_date) }}
                        {{
                            date.from_date != date.to_date
                            ? "to " + formatDate(date.to_date)
                            : ""
                        }}</v-chip>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card class="mx-auto" outlined elevation="0">
                    <v-toolbar flat dense>
                      <v-toolbar-title tag="span" style="font-weight: 400; font-size: 16px">
                        Include Date Range
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-menu ref="menu_include_dates" v-model="menu_include_dates" :close-on-content-click="false"
                        offset-x left min-width="auto">
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <v-date-picker v-model="include_dates" no-title range>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="
                              menu_include_dates = false;
                            include_dates = [];
                            ">
                            Cancel
                          </v-btn>
                          <v-btn text color="primary" @click="
                                                        var to_date = '';
                              if (include_dates.length != 2) {
                                to_date = include_dates[0];
                              } else {
                                to_date = include_dates[1];
                              }
                              menu_include_dates = false;
                              availability.availability_tool_include_date.push({
                                from_date: include_dates[0],
                                to_date: to_date,
                              });
                              include_dates = [];
                              ">
                            Add
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-toolbar>
                    <v-card-text class="pa-1">
                      <v-chip v-for="(
                          date, index
                        ) in availability.availability_tool_include_date" :key="index" dense close
                        close-icon="mdi-delete" @click:close="
                              availability.availability_tool_include_date.splice(
                                index,
                                1
                              )
                              " class="ma-1">{{ formatDate(date.from_date) }}
                        {{
                              date.from_date != date.to_date
                              ? "to " + formatDate(date.to_date)
                              : ""
                        }}
                      </v-chip>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-card flat outlined>
                    <v-toolbar flat dense>
                      <v-toolbar-title style="font-weight: 400; font-size: 16px">
                        Include Days
                      </v-toolbar-title>
                      <v-spacer></v-spacer>

                      <v-checkbox hide-details="true" v-model="days_all" :true-value="1" :false-value="0"
                        label="Select All Days"></v-checkbox>
                    </v-toolbar>
                    <v-card-text class="pt-0 pb-0 d-flex">
                      <v-container>
                        <v-row>
                          <v-col><v-checkbox v-model="availability.monday" :true-value="1" :false-value="0"
                              label="Monday"></v-checkbox></v-col>
                          <v-col>
                            <v-checkbox v-model="availability.tuesday" :true-value="1" :false-value="0"
                              label="Tuesday"></v-checkbox></v-col>
                          <v-col>
                            <v-checkbox v-model="availability.wednesday" :true-value="1" :false-value="0"
                              label="Wednesday"></v-checkbox></v-col>
                          <v-col>
                            <v-checkbox v-model="availability.thursday" :true-value="1" :false-value="0"
                              label="Thursday"></v-checkbox></v-col>
                          <v-col><v-checkbox v-model="availability.friday" :true-value="1" :false-value="0"
                              label="Friday"></v-checkbox></v-col>
                          <v-col><v-checkbox v-model="availability.saturday" :true-value="1" :false-value="0"
                              label="Saturday"></v-checkbox></v-col>
                          <v-col><v-checkbox v-model="availability.sunday" :true-value="1" :false-value="0"
                              label="Sunday"></v-checkbox></v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="ml-2">
                <v-col cols="12" sm="12" md="12">
                  <v-btn elevation="2" @click="create_availability()" large color="secondary">Create Availability</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
      
<script>
import CommonApi from "@/services/CommonApi";

export default {
  name: "ManageAvailability",
  data() {
    return {
      days_all: 1,
      menu_from_date: false,
      menu_to_date: false,
      exclude_date_list: [],
      menu_exclude_dates: false,
      exclude_dates: [],
      include_date_list: [],
      menu_include_dates: false,
      include_dates: [],
      allowedStep: (m) => m % 10 === 0,
      availability: {
        doctor: "",
        from_date: "",
        to_date: "",
        availability_tool_exclude_date: [],
        availability_tool_include_date: [],
        monday: 1,
        tuesday: 1,
        wednesday: 1,
        thursday: 1,
        friday: 1,
        saturday: 1,
        sunday: 1,
      },
      user: {
        current_password: "",
        new_password: "",
      },
      rules: {
        required: [(v) => v.length > 0 || "Required field."],
        required_list: [(v) => !!v || "Required field."],
      },
      validate_surgeon: {
        select1: [
          (v) => Boolean(Object.keys(v || {})[0]) || "Field is required",
        ],
      },
      partner_list: [],
      surgeon_items: [],
      selected_surgeon: null,
      selected_partner: null,
      time_range: [
        "08:00:00",
        "08:10:00",
        "08:20:00",
        "08:30:00",
        "08:40:00",
        "08:50:00",
        "09:00:00",
        "09:10:00",
        "09:20:00",
        "09:30:00",
        "09:40:00",
        "09:50:00",
        "10:00:00",
        "10:10:00",
        "10:20:00",
        "10:30:00",
        "10:40:00",
        "10:50:00",
        "11:00:00",
        "11:10:00",
        "11:20:00",
        "11:30:00",
        "11:40:00",
        "11:50:00",
        "12:00:00",
        "12:10:00",
        "12:20:00",
        "12:30:00",
        "12:40:00",
        "12:50:00",
        "13:00:00",
        "13:10:00",
        "13:20:00",
        "13:30:00",
        "13:40:00",
        "13:50:00",
        "14:00:00",
        "14:10:00",
        "14:20:00",
        "14:30:00",
        "14:40:00",
        "14:50:00",
        "15:00:00",
        "15:10:00",
        "15:20:00",
        "15:30:00",
        "15:40:00",
        "15:50:00",
        "16:00:00",
        "16:10:00",
        "16:20:00",
        "16:30:00",
        "16:40:00",
        "16:50:00",
        "17:00:00",
        "17:10:00",
        "17:20:00",
        "17:30:00",
        "17:40:00",
        "17:50:00",
        "18:00:00",
        "18:10:00",
        "18:20:00",
        "18:30:00",
        "18:40:00",
        "18:50:00",
        "19:00:00",
        "19:10:00",
        "19:20:00",
        "19:30:00",
        "19:40:00",
        "19:50:00",
        "20:00:00",
        "20:10:00",
        "20:20:00",
        "20:30:00",
        "20:40:00",
        "20:50:00",
        "21:00:00",
      ],
    };
  },
  computed: {
    include_date_range_text() {
      return this.include_dates.join(" to ");
    },
    computed_from_date() {
      return this.formatDate(this.availability.from_date);
    },
    computed_to_date() {
      return this.formatDate(this.availability.to_date);
    },
  },
  async mounted() {
    this.selected_partner = localStorage.getItem("selected_partner")
      ? JSON.parse(localStorage.getItem("selected_partner"))
      : "";
    this.selected_surgeon = localStorage.getItem("selected_surgeon")
      ? JSON.parse(localStorage.getItem("selected_surgeon"))
      : "";
    this.get_partner_list();
    if (this.selected_partner) {
      this.get_surgeon_list();
      this.set_doctor();
    }
  },
  watch: {
    days_all(new_value) {
      if (new_value == 1) {
        this.availability.monday = 1;
        this.availability.tuesday = 1;
        this.availability.wednesday = 1;
        this.availability.thursday = 1;
        this.availability.friday = 1;
        this.availability.saturday = 1;
        this.availability.sunday = 1;
      } else {
        this.availability.monday = 0;
        this.availability.tuesday = 0;
        this.availability.wednesday = 0;
        this.availability.thursday = 0;
        this.availability.friday = 0;
        this.availability.saturday = 0;
        this.availability.sunday = 0;
      }
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    selected_partner_change() {
      this.get_surgeon_list();
      this.selected_surgeon = "";
      this.availability.doctor = "";
    },
    set_doctor() {
      this.availability.doctor = this.selected_surgeon.name;
    },
    custom_include_range_title() {
      return this.include_date_range_text;
    },
    reset_form() {
      this.availability = {
        doctor: null,
        from_date: "",
        to_date: "",
        availability_tool_exclude_date: [],
        availability_tool_include_date: [],
        monday: 1,
        tuesday: 1,
        wednesday: 1,
        thursday: 1,
        friday: 1,
        saturday: 1,
        sunday: 1,
      };
    },
    async create_availability() {
      this.$refs.availability_form.validate();
      if (!this.$refs.availability_form.validate()) {
        return;
      }
      // let data = {
      //   data: ,
      // };
      await CommonApi.post_data(
        "create_availability_0027",
        this.availability
      ).then((res) => {
        if (res) {
          this.$toast.success(res.message, {
            position: "bottom-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.reset_form();
          this.$refs.availability_form.resetValidation();
        } else {
          this.$toast.error(res.messages, {
            position: "bottom-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      });
    },
    // Get Get Surgeon list
    async get_surgeon_list() {
      var data = {
        partner: this.selected_partner ? this.selected_partner.name : "",
      };
      await CommonApi.post_data("get_surgeon_list_0009", data).then((res) => {
        if (res.data.length && res.status == 200) {
          this.surgeon_items = res.data;
        } else {
          this.surgeon_items = [];
        }
      });
    },
    // Get Get Partner list
    async get_partner_list() {
      await CommonApi.post_data("get_partner_list_0029").then((res) => {
        if (res.data.length && res.status == 200) {
          this.partner_list = res.data;
        }
      });
    },
  },
};
</script>
