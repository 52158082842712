<template>
  <v-container class="surgery_booking">
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-tabs>
            <v-tab>Profile Details</v-tab>
            <v-tab>Change Password</v-tab>

            <v-tab-item>
              <v-form ref="form1" lazy-validation>
                <v-container>
                  <v-row class="surgery_row">
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-text-field
                        name="Title"
                        label="Title"
                        type="text"
                        placeholder="Enter Title"
                        outlined
                        dense
                        v-model="profile_details.title"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-text-field
                        name="Doctor Name"
                        label="Doctor Name"
                        type="text"
                        placeholder="Enter Doctor Name"
                        outlined
                        dense
                        v-model="profile_details.doctor_name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-text-field
                        name="Mobile Number"
                        label="Mobile Number"
                        type="text"
                        @keypress="onlyNumber"
                        placeholder="Enter Mobile Number"
                        outlined
                        dense
                        v-model="profile_details.work_mobile"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-text-field
                        name="Work Email ID"
                        label="Work Email ID"
                        type="text"
                        placeholder="Enter Work Email ID"
                        outlined
                        dense
                        @blur="validateEmail(profile_details.work_email)"
                        v-model="profile_details.work_email"
                      ></v-text-field>
                      <p class="d-flex invalid" v-if="invalidEmail">
                        Email Id is not valid
                      </p>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-select
                        :items="sex"
                        label="Sex"
                        outlined
                        dense
                        v-model="profile_details.sex"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-text-field
                        name="licence Idgmc No"
                        label="licence Idgmc No"
                        type="text"
                        placeholder="Enter licence Idgmc No"
                        outlined
                        dense
                        v-model="profile_details.licence_idgmc_no"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-text-field
                        name="User"
                        label="User"
                        type="text"
                        placeholder="User"
                        outlined
                        dense
                        readonly
                        v-model="profile_details.user"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-textarea
                        name="Note"
                        label="Note"
                        type="text"
                        placeholder="Enter Note"
                        outlined
                        dense
                        v-model="profile_details.add_note"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row class="ml-2">
                    <v-col cols="12" sm="12" md="12">
                      <v-btn
                        elevation="2"
                        @click="update_profile()"
                        large
                        color="secondary"
                        >Update Profile Details</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-tab-item>

            <v-tab-item>
              <v-form ref="form" lazy-validation>
                <v-container>
                  <v-row class="surgery_row">
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-text-field
                        name="Current Password"
                        label="Current Password"
                        type="password"
                        placeholder="Enter Current Password"
                        :rules="rules.current_password"
                        required
                        outlined
                        dense
                        v-model="user.current_password"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-text-field
                        name="New Password"
                        label="New Password"
                        type="password"
                        placeholder="Enter New Password"
                        :rules="rules.new_password"
                        required
                        outlined
                        dense
                        v-model="user.new_password"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="ml-2">
                    <v-col cols="12" sm="12" md="12">
                      <v-btn
                        elevation="2"
                        @click="change_password()"
                        large
                        color="secondary"
                        >Change Password</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
      
<script>
import CommonApi from "@/services/CommonApi";

export default {
  name: "ManageProfile",
  data() {
    return {
      user: {
        current_password: "",
        new_password: "",
      },
      profile_details: {
        title: "",
        doctor_name: "",
        work_mobile: "",
        work_email: "",
        sex: "",
        licence_idgmc_no: "",
        add_note: "",
        user: "",
      },
      sex: ["Male", "Female", "Other"],
      invalidEmail: false,
      rules: {
        current_password: [
          (v) => v.length > 0 || "Current Password is required",
        ],
        new_password: [(v) => v.length > 0 || "New Password is required"],
      },
    };
  },
  methods: {
    validateEmail(mail) {
      // eslint-disable-next-line no-useless-escape
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (mail.match(mailformat) || mail === "") {
        this.invalidEmail = false;
        return true;
      } else {
        this.invalidEmail = true;
        return false;
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (keyCode < 48 || keyCode > 57) &&
        keyCode !== 46 &&
        keyCode !== 43 &&
        keyCode !== 45
      ) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    reset_user_data() {
      this.user.current_password = "";
      this.user.new_password = "";
      this.$refs.form.resetValidation();
    },
    set_profile_data(data) {
      this.profile_details.title = data.title;
      this.profile_details.doctor_name = data.doctor_name;
      this.profile_details.work_mobile = data.work_mobile;
      this.profile_details.work_email = data.work_email;
      this.profile_details.sex = data.sex;
      this.profile_details.licence_idgmc_no = data.licence_idgmc_no;
      this.profile_details.add_note = data.add_note;
      this.profile_details.user = data.user;
    },
    async get_profile_details() {
      await CommonApi.post_data("get_profile_details_0024").then((res) => {
        if (res && res.data && res.status == 200) {
          this.set_profile_data(res.data);
        } else {
          this.$toast.error(res.message, {
            position: "bottom-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      });
    },
    async change_password() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        let data = {
          data: {
            current_password: this.user.current_password,
            new_password: this.user.new_password,
          },
        };
        await CommonApi.post_data("change_password_0022", data).then((res) => {
          if (res.status === 500) {
            this.$toast.error(res.data.message, {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          } else {
            this.$toast.success(res.message, {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.reset_user_data();
          }
        });
      }
    },
    async update_profile() {
      this.$refs.form1.validate();
      if (this.$refs.form1.validate() && !this.invalidEmail) {
        let data = {
          data: {
            title: this.profile_details.title,
            doctor_name: this.profile_details.doctor_name,
            work_mobile: this.profile_details.work_mobile,
            work_email: this.profile_details.work_email,
            sex: this.profile_details.sex,
            licence_idgmc_no: this.profile_details.licence_idgmc_no,
            add_note: this.profile_details.add_note,
          },
        };
        await CommonApi.post_data("update_profile_0023", data).then((res) => {
          if (res && res.status == 200) {
            this.$toast.success(res.message, {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          } else {
            this.$toast.error(res.message, {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          }
          this.get_profile_details();
        });
      }
    },
  },
  mounted() {
    this.get_profile_details();
  },
};
</script>
