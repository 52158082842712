<template>
  <div>
    <v-app id="inspire" :key="componentKey">
      <div style="margin-top: 20px; margin-left: 10px" class="slot_calendar">
        <!-- <v-row class="d-flex justify-space-between mb-5"> -->
        <v-container class="surgery_booking">
          <v-row>
            <v-col cols="3" sm="3" class="mr-2">
              <v-autocomplete solo item-value="name" item-text="doctor_name" return-object required
                v-model="selected_partner" :items="partner_list" label="Select Partner" @change="selected_surgeon = ''"
                outlined clearable dense>
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.doctor_name }} - {{ data.item.name }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.doctor_name }} - {{ data.item.name }}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="3" sm="3" class="mr-5">
              <v-autocomplete solo item-value="name" item-text="doctor_name" return-object required
                v-model="selected_surgeon" :items="surgeon_items" label="Select Surgeon" outlined clearable dense>
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  <small>{{ data.item.doctor_name }} - {{ data.item.name }}</small>
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  <small>{{ data.item.doctor_name }} - {{ data.item.name }}</small>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="5" sm="5" class="d-flex justify-end">
              <v-menu ref="manage_time_menu" v-model="manage_time_menu" :close-on-content-click="false" location="bottom"
                offset-y max-width="700px" min-width="700px">
                <template v-slot:activator="{ on }">
                  <v-btn class="mr-4" v-on="on" title="Manage time range"><v-icon>mdi-calendar-clock</v-icon></v-btn>
                </template>
                <v-card class="pa-5">
                  <v-range-slider :tick-labels="time_range_ticks" ticks="always" :min="0" :max="1440" :step="60"
                    class="mt-5" color="grey" track-color="grey" track-fill-color="#424242" thumb-color="grey"
                    v-model="time_range">
                  </v-range-slider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-menu>

              <v-menu ref="month_menu" v-model="month_menu" :close-on-content-click="false" location="bottom" offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" title="Go to month view">
                    <v-icon light>mdi-calendar-month</v-icon>
                  </v-btn>
                </template>
                <v-card class="pa-5">
                  <vue-cal :time="false" active-view="month" :clickToNavigate="true"
                    :disable-views="['years', 'week', 'day']" @cell-focus="
                                          if (current_mini_view == 'month') {
                      selectedDate = $event;
                      month_menu = false;
                    }
                      " @view-change="current_mini_view = $event.view" style="max-width: 400px">
                  </vue-cal>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <!-- :on-event-click="onEventClick" 
              :split-days="splitDays"
              :on-event-create="onEventCreate"
              @event-drag-create="
                showEventCreationDialog = true;
                eventDragCreate('event-drag-create', $event);
              "
              @event-duration-change="
                onEventDurationChange('event-duration-change', $event)
              "
              @view-change="changeView('view-change', $event)"
              @event-drop="onEventDrop($event)"

              -->
              <vue-cal ref="vuecal" :todayButton="true" :disable-views="disabledView" :selected-date="selectedDate"
                :events="events" events-on-month-view="short" :sticky-split-labels="stickySplitLabels"
                :min-cell-width="minCellWidth" :min-split-width="minSplitWidth" :time-from="time_range[0]"
                :time-to="time_range[1]" :time-step="timeStep" @view-change="changeView('view-change', $event)">
              </vue-cal>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-app>
  </div>
</template>
  
<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import CommonApi from "@/services/CommonApi";
// const Swal = require("sweetalert2");
// const dailyHours = {
//   from: 9 * 60,
//   to: 18 * 60,
//   class: "business-hours",
//   split: 1,
// };

export default {
  name: "AvailabilityCalender",
  components: { VueCal },
  data: () => ({
    validate_surgeon: {
      select1: [
        (v) => Boolean(Object.keys(v || {})[0]) || "Surgeon is required",
      ],
    },
    surgeon_items: [],
    partner_list: [],
    selected_surgeon: null,
    selected_partner: null,
    dialog: false,
    current_mini_view: "month",
    selectedDate: null,
    month_menu: false,
    manage_time_menu: false,
    time_range: [480, 1260],
    time_range_ticks: [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
    ],
    start_time_range: [
      "08:00:00",
      "08:10:00",
      "08:20:00",
      "08:30:00",
      "08:40:00",
      "08:50:00",
      "09:00:00",
      "09:10:00",
      "09:20:00",
      "09:30:00",
      "09:40:00",
      "09:50:00",
      "10:00:00",
      "10:10:00",
      "10:20:00",
      "10:30:00",
      "10:40:00",
      "10:50:00",
      "11:00:00",
      "11:10:00",
      "11:20:00",
      "11:30:00",
      "11:40:00",
      "11:50:00",
      "12:00:00",
      "12:10:00",
      "12:20:00",
      "12:30:00",
      "12:40:00",
      "12:50:00",
      "13:00:00",
      "13:10:00",
      "13:20:00",
      "13:30:00",
      "13:40:00",
      "13:50:00",
      "14:00:00",
      "14:10:00",
      "14:20:00",
      "14:30:00",
      "14:40:00",
      "14:50:00",
      "15:00:00",
      "15:10:00",
      "15:20:00",
      "15:30:00",
      "15:40:00",
      "15:50:00",
      "16:00:00",
      "16:10:00",
      "16:20:00",
      "16:30:00",
      "16:40:00",
      "16:50:00",
      "17:00:00",
      "17:10:00",
      "17:20:00",
      "17:30:00",
      "17:40:00",
      "17:50:00",
      "18:00:00",
      "18:10:00",
      "18:20:00",
      "18:30:00",
      "18:40:00",
      "18:50:00",
      "19:00:00",
      "19:10:00",
      "19:20:00",
      "19:30:00",
      "19:40:00",
      "19:50:00",
      "20:00:00",
      "20:10:00",
      "20:20:00",
      "20:30:00",
      "20:40:00",
      "20:50:00",
      "21:00:00",
    ],
    timeStep: 60,
    timeFrom: 8 * 60,
    timeTo: 21 * 60,
    componentKey: 0,
    selectedEvent: {},
    showDialog: false,
    events: [],
    stickySplitLabels: true,
    minCellWidth: 0,
    minSplitWidth: 0,
    isSlot: true,
    week_start_date: "",
    week_end_date: "",
    isMobile: false,
    disabledView: [],
  }),
  watch: {
    // whenever question changes, this function will run
    time_range(new_time_range) {
      localStorage.setItem("time_range", JSON.stringify(new_time_range));
    },
    selected_partner(value) {
      this.get_surgeon_list();
      localStorage.setItem("selected_partner", JSON.stringify(value));
    },
    selected_surgeon(value) {
      localStorage.setItem("selected_surgeon", JSON.stringify(value));
      // this.load_data("slot_event.json");
      const today = new Date();
      // Get the first day of the current week (Monday)
      const firstDay = new Date(
        today.setDate(
          today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
        )
      );
      // Get the last day of the current week (Sunday)
      const lastDay = new Date(
        today.setDate(today.getDate() - today.getDay() + 7)
      );
      this.week_start_date = firstDay.toISOString().split("T")[0];
      this.week_end_date = lastDay.toISOString().split("T")[0];

      this.get_availability_details(
        firstDay.toISOString().split("T")[0],
        lastDay.toISOString().split("T")[0]
      );
    },
  },
  methods: {
    // Get Get Surgeon list
    async get_surgeon_list() {
      var data = {
        partner: this.selected_partner ? this.selected_partner.name : "",
      };
      await CommonApi.post_data("get_surgeon_list_0009", data).then((res) => {
        if (res.data.length && res.status == 200) {
          this.surgeon_items = res.data;
        } else {
          this.surgeon_items = [];
        }
      });
    },
    // Get Get Partner list
    async get_partner_list() {
      await CommonApi.post_data("get_partner_list_0029").then((res) => {
        if (res.data.length && res.status == 200) {
          this.partner_list = res.data;
        }
      });
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
      if (this.isMobile) {
        this.disabledView = ["month", "years", "year", "week"];
        this.$refs.vuecal.switchView("day");
      } else {
        this.disabledView = ["month", "years", "year"];
        this.$refs.vuecal.switchView("week");
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    convertDate(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    },
    changeView(e, event) {
      if (event.view === "week") {
        // this.timeFrom = 0 * 60,
        // this.timeTo = 24 * 60
        this.timeStep = 60;
        // this.load_data("slot_event.json");
        this.week_start_date = this.convertDate(event.startDate);
        this.week_end_date = this.convertDate(event.endDate);
        this.get_availability_details(
          this.convertDate(event.startDate),
          this.convertDate(event.endDate)
        );
      } else {
        this.get_availability_details(
          this.convertDate(event.startDate),
          this.convertDate(event.startDate)
        );
      }
    },
    // Get Slot Details
    async get_availability_details(from_date, to_date) {
      let data = {
        from_date: from_date,
        to_date: to_date,
        surgeon: this.selected_surgeon ? this.selected_surgeon.name : "",
      };
      // partner_id: "DOC011"
      await CommonApi.post_data("get_availability_0028", data).then((res) => {
        if (res.status == 200) {
          this.events = res.data;
        } else {
          this.$toast.error(res.message, {
            position: "bottom-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      });
    },
  },
  async mounted() {
    this.selected_partner = localStorage.getItem("selected_partner")
      ? JSON.parse(localStorage.getItem("selected_partner"))
      : "";
    this.selected_surgeon = localStorage.getItem("selected_surgeon")
      ? JSON.parse(localStorage.getItem("selected_surgeon"))
      : "";
    this.onResize();
    this.time_range = localStorage.getItem("time_range")
      ? JSON.parse(localStorage.getItem("time_range"))
      : [480, 1260];
    window.addEventListener("resize", this.onResize, { passive: true });

    // this.load_data("slot_event.json");
    const today = new Date();
    // Get the first day of the current week (Monday)
    const firstDay = new Date(
      today.setDate(
        today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1)
      )
    );
    // Get the last day of the current week (Sunday)
    const lastDay = new Date(
      today.setDate(today.getDate() - today.getDay() + 7)
    );
    this.week_start_date = firstDay.toISOString().split("T")[0];
    this.week_end_date = lastDay.toISOString().split("T")[0];

    await this.get_availability_details(
      firstDay.toISOString().split("T")[0],
      lastDay.toISOString().split("T")[0]
    );
    this.get_partner_list();
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  // get_surgeon_label(surgeon) {
  //   return `${surgeon.doctor_name} - ${surgeon.name}`;
  // },
};
// {
//   start: "2022-11-19 12:00",
//   end: "2022-11-19 14:00",
//   title: "LUNCH",
//   class: "lunch",
//   background: true,
//   split: 1,
// },
// {
//   start: "2022-11-20 12:00",
//   end: "2022-11-20 14:00",
//   title: "LUNCH",
//   class: "lunch",
//   background: true,
//   split: 2,
// },
// {
//   start: "2022-11-21 12:00",
//   end: "2022-11-21 14:00",
//   title: "LUNCH",
//   class: "lunch",
//   background: true,
//   split: 3,
// },
// {
//   start: "2022-11-22 12:00",
//   end: "2022-11-22 14:00",
//   title: "LUNCH",
//   class: "lunch",
//   background: true,
//   split: 1,
// },
// {
//   start: "2022-11-23 12:00",
//   end: "2022-11-23 14:00",
//   title: "LUNCH",
//   class: "lunch",
//   background: true,
//   split: 1,
// },
// {
//   start: "2022-11-19 10:00",
//   end: "2022-11-19 11:30",
//   title: "appointment",
//   content: '<i class="icon material-icons">local_hospital</i>',
//   class: "health",
//   split: 1, // Has to match the id of the split you have set (or integers if none).
// },
// {
//   start: "2022-11-19 18:00",
//   end: "2022-11-19 20:00",
//   title: "Dentist appointment",
//   content: '<i class="icon material-icons">local_hospital</i>',
//   class: "health",
//   split: 2,
// },
// {
//   start: "2022-11-20 18:30",
//   end: "2022-11-20 20:30",
//   title: "Crossfit",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   class: "sport",
//   split: 1,
// },
// {
//   start: "2022-11-14 10:30",
//   end: "2022-11-14 12:30",
//   title: "General Surgery",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 1,
// },
// {
//   start: "2022-11-14 8:00",
//   end: "2022-11-14 10:00",
//   title: "Head & Neck Surgery",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 2,
// },
// {
//   start: "2022-11-14 01:00 PM",
//   end: "2022-11-14 02:00 PM",
//   title: "General Surgery",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 3,
// },
// {
//   start: "2022-11-14 04:00 PM",
//   end: "2022-11-14 06:00 PM",
//   title: "Head & Neck Surgery",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 3,
// },
// {
//   start: "2022-11-15 1:00 PM",
//   end: "2022-11-15 03:00 PM",
//   title: "General Surgery",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 3,
// },
// {
//   start: "2022-11-15 10:00 AM",
//   end: "2022-11-15 12:00 PM",
//   title: "Head & Neck Surgery",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 1,
// },
// {
//   start: "2022-11-15 3:00 PM",
//   end: "2022-11-15 05:00 PM",
//   title: "Surgery disabled",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 2,
//   deletable: false,
//   resizable: false,
//   draggable: false,
// },
// {
//   start: "2022-11-17 1:00 PM",
//   end: "2022-11-17 03:00 PM",
//   title: "General Surgery",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 3,
// },
// {
//   start: "2022-11-17 10:00 AM",
//   end: "2022-11-17 12:00 PM",
//   title: "Head & Neck Surgery",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 1,
// },
// {
//   start: "2022-11-17 3:00 PM",
//   end: "2022-11-17 05:00 PM",
//   title: "Surgery disabled",
//   content: '<i class="icon material-icons">fitness_center</i>',
//   split: 2,
//   deletable: false,
//   resizable: false,
//   draggable: false,
// },
// {
//   start: "2022-11-16 03:30",
//   end: "2022-11-16 05:30",
//   title: "Physiotherapy",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "Physiotherapy",
//   split: 3,
// },
// {
//   start: "2022-11-16 06:30",
//   end: "2022-11-16 08:30",
//   title: "Physiotherapy",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "General Surgery",
//   split: 3,
// },
// {
//   start: "2022-11-16 09:30",
//   end: "2022-11-16 10:30",
//   title: "Checkup",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "General Surgery",
//   split: 1,
// },
// {
//   start: "2022-11-17 09:30 AM",
//   end: "2022-11-17 11:30 AM",
//   title: "Physiotherapy",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "Physiotherapy",
//   split: 3,
// },
// {
//   start: "2022-11-17 06:30",
//   end: "2022-11-17 08:30",
//   title: "Physiotherapy",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "General Surgery",
//   split: 3,
// },
// {
//   start: "2022-11-17 08:30",
//   end: "2022-11-17 09:30",
//   title: "Checkup",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "General Surgery",
//   split: 2,
// },
// {
//   start: "2022-11-18 09:30",
//   end: "2022-11-18 10:30",
//   title: "Checkup",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "General Surgery",
//   split: 1,
// },
// {
//   start: "2022-11-18 02:30 PM",
//   end: "2022-11-18 03:30 PM",
//   title: "Physiotherapy",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "Physiotherapy",
//   split: 3,
// },
// {
//   start: "2022-11-18 06:30",
//   end: "2022-11-18 08:30",
//   title: "Physiotherapy",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "General Surgery",
//   split: 3,
// },
// {
//   start: "2022-11-18 12:30 PM",
//   end: "2022-11-18 01:30 PM",
//   title: "Checkup",
//   content: '<i class="icon material-icons">physiotherapy_center</i>',
//   class: "General Surgery",
//   split: 2,
// },
// {
//   start: "2022-11-23 02:30",
//   end: "2022-11-23 03:30",
//   title: "surgery",
//   content: '<i class="icon material-icons">surgery_center</i>',
//   class: "surgery",
//   split: 3,
// },
</script>
  
<style scoped lang="scss">
// General styles.
* {
  font-size: 12px;
  margin: 0;
  padding: 0;
}

html,
body,
#app {
  height: 100%;
}

#app {
  padding: 40px;
  box-sizing: border-box;
}

// a {
//   position: fixed;
//   top: 5px;
//   right: 5px;
//   color: #333;
// }
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}
</style>

