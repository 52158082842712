export function isAuthenticated(to, from, next) {
  let api_key = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).api_key
    : null;
  let api_secret = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).api_secret
    : null;

  if (api_key && api_secret && to.path !== "/login") {
    return next(); // allow to enter route
  } else if (api_key && api_secret && to.path == "/login") {
    return next("/dashboard");
  } else {
    return next("/login");
  }
}

export const AuthMixin = {
  methods: {
    isAuthenticated,
  },
};
