<template>
  <div v-if="getOverlay" class="loader-overlay">
    <clip-loader color="#F78021" class="spinner"></clip-loader>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
const { mapActions, mapGetters } = createNamespacedHelpers("loader");

import axios from "axios";
export default {
  name: "NewLoader",
  components: { ClipLoader },
  computed: { ...mapGetters(["getOverlay"]) },
  mounted() {
    this.enableInterceptor();
  },
  created() {
    this.$root.$refs.NewLoader = this;
  },
  methods: {
    ...mapActions(["showLoader", "hideLoader"]),
    enableInterceptor() {
      axios.interceptors.request.use(
        (config) => {
          this.showLoader();
          return config;
        },
        (error) => {
          this.hideLoader();
          return Promise.reject(error);
        }
      );

      axios.interceptors.response.use(
        (response) => {
          this.hideLoader();
          return response;
        },
        (error) => {
          this.hideLoader();
          return Promise.reject(error);
        }
      );
    },
    disableInterceptor() {
      axios.interceptors.request.eject(this.axiosInterceptor);
    },
  },
};
</script>

<style lang="scss">
.spinner {
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  background: transparent;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  cursor: pointer;
}
</style>

<style lang="scss">
.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  cursor: pointer;

  span.text {
    display: inline-block;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }

  .loader {
    animation: loader-animate 1.5s linear infinite;
    clip: rect(0, 80px, 80px, 40px);
    height: 80px;
    width: 80px;
    position: absolute;
    left: calc(50% - 40px);
    top: calc(50% - 40px);

    &:after {
      animation: loader-animate-after 1.5s ease-in-out infinite;
      clip: rect(0, 80px, 80px, 40px);
      content: "";
      border-radius: 50%;
      height: 80px;
      width: 80px;
      position: absolute;
    }
  }

  @keyframes loader-animate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(220deg);
    }
  }

  @keyframes loader-animate-after {
    0% {
      box-shadow: inset #fff 0 0 0 17px;
      transform: rotate(-140deg);
    }

    50% {
      box-shadow: inset #fff 0 0 0 2px;
    }

    100% {
      box-shadow: inset #fff 0 0 0 17px;
      transform: rotate(140deg);
    }
  }
}
</style>