<template>
  <div>
    <v-app id="inspire">
      <div style="margin-top: 20px; margin-left: 35px">
        <v-row>
          <v-col sm="4">
            <v-autocomplete solo item-value="name" item-text="doctor_name" return-object required
              v-model="selected_partner" :items="partner_list" label="Select Partner" @change="selected_partner_change"
              outlined dense clearable>
              <template slot="selection" slot-scope="data">
                <!-- HTML that describe how select should render selected items -->
                <small>{{ data.item.doctor_name }} - {{ data.item.name }}</small>
              </template>
              <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                <small>{{ data.item.doctor_name }} - {{ data.item.name }}</small>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col sm="4">
            <v-autocomplete solo item-value="name" item-text="doctor_name" return-object required
              v-model="selected_surgeon" @change="selected_surgeon_change" :items="surgeon_items" label="Select Surgeon"
              outlined dense clearable>
              <template slot="selection" slot-scope="data">
                <!-- HTML that describe how select should render selected items -->
                <small>{{ data.item.doctor_name }} - {{ data.item.name }}</small>
              </template>
              <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                <small>{{ data.item.doctor_name }} - {{ data.item.name }}</small>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-col cols="12" sm="12" md="12" style="margin-left: -15px">
          <v-card>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" v-for="(item, index) in patient_fields" :key="index">
                      {{ item }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in patient_items" :key="index">
                    <td>{{ item.name }}</td>
                    <td>{{ item.title }} {{ item.patients_name }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.mobile }}</td>
                    <td>{{ formatDate(item.date_of_birth) }}</td>
                    <td>{{ item.sex }}</td>
                    <td>{{ item.marital_status }}</td>
                    <td>{{ item.blood_group }}</td>
                    <td>{{ item.bmi }}</td>

                    <td @click="navigate_to(item.name)" style="cursor: pointer">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-pencil" viewBox="0 0 16 16">
                        <path
                          d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                      </svg>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <p class="no-data" v-if="!patient_items.length && !isApiCalling">
              No data found
            </p>
          </v-card>
        </v-col>
      </div>
    </v-app>
  </div>
</template>
        
<script>
import CommonApi from "@/services/CommonApi";

export default {
  name: "PatientList",
  data() {
    return {
      patient_fields: [
        "Patient ID",
        "Patient Name",
        "Email",
        "Mobile No",
        "Date Of Birth",
        "Sex",
        "Marital Status",
        "Blood Group",
        "Bmi",
        "Edit",
      ],
      patient_items: [],
      isApiCalling: true,
      validate_surgeon: {
        select1: [
          (v) => Boolean(Object.keys(v || {})[0]) || "Field is required",
        ],
      },
      partner_list: [],
      selected_partner: null,
      selected_surgeon: null,
      surgeon_items: [],
    };
  },
  // watch: {
  //   selected_partner(value) {
  //     this.get_surgeon_list();
  //     this.get_patient_list();
  //     localStorage.setItem("selected_partner", JSON.stringify(value));
  //   },
  //   selected_surgeon(value) {
  //     this.get_patient_list();
  //     localStorage.setItem("selected_surgeon", JSON.stringify(value));
  //   },
  // },
  methods: {
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    selected_partner_change(value) {
      this.selected_surgeon = "";
      this.get_surgeon_list();
      this.get_patient_list();
      localStorage.setItem("selected_partner", JSON.stringify(value));
      localStorage.setItem("selected_surgeon", null);
    },
    selected_surgeon_change(value) {
      this.get_patient_list();
      localStorage.setItem("selected_surgeon", JSON.stringify(value));
    },
    // Get Get Surgeon list
    async get_surgeon_list() {
      var data = {
        partner: this.selected_partner ? this.selected_partner.name : "",
      };
      await CommonApi.post_data("get_surgeon_list_0009", data).then((res) => {
        if (res.data.length && res.status == 200) {
          this.surgeon_items = res.data;
        } else {
          this.surgeon_items = [];
        }
      });
    },
    // Get Get Partner list
    async get_partner_list() {
      await CommonApi.post_data("get_partner_list_0029").then((res) => {
        if (res.data.length && res.status == 200) {
          this.partner_list = res.data;
        }
      });
    },
    convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    },
    navigate_to(patient_id) {
      this.$router.push("/update-patient/" + patient_id);
    },
    // Get Patient List
    async get_patient_list() {
      let data = {
        list_view: true,
        partner: this.selected_partner ? this.selected_partner.name : null,
        surgeon: this.selected_surgeon ? this.selected_surgeon.name : null,
      };
      await CommonApi.post_data("get_patient_list_0008", data).then((res) => {
        if (res.status == 200) {
          this.patient_items = res.data;
          this.isApiCalling = false;
        } else {
          this.$toast.error(res.message, {
            position: "bottom-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      });
    },
  },
  mounted() {
    this.selected_partner = localStorage.getItem("selected_partner")
      ? JSON.parse(localStorage.getItem("selected_partner"))
      : "";
    this.selected_surgeon = localStorage.getItem("selected_surgeon")
      ? JSON.parse(localStorage.getItem("selected_surgeon"))
      : "";
    this.get_patient_list();
    this.get_partner_list();
    if (this.selected_partner) {
      this.get_surgeon_list();
    }
  },
};
</script>