const state = {
  overlay: false,
};

// getters
const getters = {
  getOverlay: (state) => state.overlay,
};

// mutations
const mutations = {
  showLoader(state) {
    state.overlay = true;
  },
  hideLoader(state) {
    state.overlay = false;
  },
};

// actions
const actions = {
  showLoader({ commit }) {
    commit("showLoader");
  },
  hideLoader({ commit }) {
    commit("hideLoader");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
