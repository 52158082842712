<template>
  <v-container class="surgery_booking">
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-tabs>
            <v-tab>Personal Details</v-tab>
            <v-tab>Address</v-tab>

            <v-tab-item>
              <v-form ref="form" lazy-validation>
                <v-container>
                  <v-row class="surgery_row">
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-text-field
                        name="Title"
                        label="Title"
                        type="text"
                        placeholder="Enter Title"
                        outlined
                        dense
                        v-model="patient_data.title"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-text-field
                        name="Patient Name"
                        label="Patient Name"
                        type="text"
                        placeholder="Enter Patient Name"
                        :rules="rules.patient"
                        required
                        outlined
                        dense
                        v-model="patient_data.name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-menu
                        v-model="date_menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computed_date_of_birth"
                            label="Date Of Birth"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="patient_data.date_of_birth"
                          @input="date_menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-select
                        :items="blood_group"
                        label="Blood Group"
                        outlined
                        dense
                        v-model="patient_data.blood_group"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-select
                        :items="sex"
                        label="Sex"
                        outlined
                        dense
                        v-model="patient_data.sex"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-text-field
                        name="Nationality"
                        label="Nationality"
                        type="text"
                        placeholder="Enter Nationality"
                        outlined
                        dense
                        v-model="patient_data.nationality"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="4" class="pa-2">
                      <v-select
                        :items="marital_status"
                        label="Marital Status"
                        outlined
                        dense
                        v-model="patient_data.marital_status"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-text-field
                        name="Mobile Number"
                        label="Mobile Number"
                        type="text"
                        @keypress="onlyNumber"
                        placeholder="Enter Mobile Number"
                        outlined
                        dense
                        v-model="patient_data.mobile_no"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-text-field
                        name="Email"
                        label="Email"
                        type="text"
                        placeholder="Enter Email"
                        outlined
                        dense
                        @blur="validateEmail(patient_data.email)"
                        v-model="patient_data.email"
                      ></v-text-field>
                      <p class="d-flex invalid" v-if="invalidEmail">
                        Email Id is not valid
                      </p>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-text-field
                        name="Ethnic Group"
                        label="Ethnic Group"
                        type="text"
                        placeholder="Enter Ethnic Group"
                        outlined
                        dense
                        v-model="patient_data.ethnic_group"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-text-field
                        name="NHS No"
                        label="NHS No"
                        type="text"
                        placeholder="Enter NHS No"
                        outlined
                        dense
                        v-model="patient_data.nhs_no"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-text-field
                        name="Weight"
                        label="Weight(In Kg)"
                        type="text"
                        placeholder="Enter Weight"
                        outlined
                        dense
                        v-model="patient_data.weight"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-text-field
                        name="Height"
                        label="Height(In Meter)"
                        type="text"
                        placeholder="Enter Height"
                        outlined
                        dense
                        v-model="patient_data.height"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-text-field
                        name="BMI"
                        label="BMI(In Kg/m2)"
                        type="text"
                        placeholder="Enter BMI"
                        outlined
                        dense
                        v-model="patient_data.bmi"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="pa-2">
                      <v-select
                        v-model="patient_data.allergies"
                        item-text="allergy"
                        item-value="allergy"
                        return-object
                        :items="allergies"
                        label="Allergies"
                        dense
                        outlined
                        multiple
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-tab-item>

            <v-tab-item>
              <v-form>
                <v-container>
                  <v-row class="surgery_row">
                    <v-col cols="12" sm="6" class="pa-2">
                      <v-text-field
                        name="Address line 1"
                        label="Address line 1"
                        type="text"
                        placeholder="Enter Address line 1"
                        outlined
                        dense
                        v-model="patient_data.current_address_line_1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <v-text-field
                        name="Address line 2"
                        label="Address line 2"
                        type="text"
                        placeholder="Enter Address line 2"
                        outlined
                        dense
                        v-model="patient_data.current_address_line_2"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <v-text-field
                        name="City"
                        label="City"
                        type="text"
                        placeholder="Enter City"
                        outlined
                        dense
                        v-model="patient_data.current_address_city"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <v-text-field
                        name="State"
                        label="State"
                        type="text"
                        placeholder="Enter State"
                        outlined
                        dense
                        v-model="patient_data.current_address_state"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <v-text-field
                        name="Post Code"
                        label="Post Code"
                        type="number"
                        placeholder="Enter Post Code"
                        outlined
                        dense
                        v-model="patient_data.current_address_zip"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pa-2">
                      <v-text-field
                        name="Country"
                        label="Country"
                        type="text"
                        placeholder="Enter Country"
                        outlined
                        dense
                        v-model="patient_data.current_address_country"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-tab-item>
          </v-tabs>

          <v-row class="ml-4">
            <v-col cols="12" sm="12" md="12">
              <v-btn
                elevation="2"
                @click="update_patient()"
                large
                class="secondary"
                >Update Patient</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
        
<script>
import CommonApi from "@/services/CommonApi";

export default {
  name: "NewPatient",
  data() {
    return {
      blood_group: ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"],
      sex: ["Male", "Female", "Other"],
      marital_status: [
        "Single",
        "Married",
        "Partner",
        "Divorced",
        "Widowed",
        "Not Disclosed",
      ],
      allergies: [{ allergy: "Sugar" }, { allergy: "Test" }],
      date_menu: false,
      invalidEmail: false,
      rules: {
        patient: [(v) => v.length > 0 || "Patient Name is required"],
      },
      patient_data: {
        title: "",
        name: "",
        date_of_birth: "",
        blood_group: "",
        sex: "",
        nationality: "",
        marital_status: "",
        mobile_no: "",
        email: "",
        ethnic_group: "",
        nhs_no: "",
        weight: "",
        height: "",
        bmi: "",
        allergies: "",
        current_address_line_1: "",
        current_address_line_2: "",
        current_address_city: "",
        current_address_state: "",
        current_address_zip: "",
        current_address_country: "",
      },
    };
  },
  computed: {
    computed_date_of_birth() {
      return this.formatDate(this.patient_data.date_of_birth);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    async update_patient() {
      this.$refs.form.validate();
      if (this.$refs.form.validate() && !this.invalidEmail) {
        let data = {
          data: {
            id: this.$route.params.id,
            title: this.patient_data.title,
            blood_group: this.patient_data.blood_group,
            mobile: this.patient_data.mobile_no,
            nhs_no: this.patient_data.nhs_no,
            email: this.patient_data.email,
            ethnic_group: this.patient_data.ethnic_group,
            weight: this.patient_data.weight,
            patients_name: this.patient_data.name,
            marital_status: this.patient_data.marital_status,
            sex: this.patient_data.sex,
            height: this.patient_data.height,
            date_of_birth: this.patient_data.date_of_birth,
            nationality: this.patient_data.nationality,
            bmi: this.patient_data.bmi,
            current_address_line_1: this.patient_data.current_address_line_1,
            current_address_line_2: this.patient_data.current_address_line_2,
            current_address_city: this.patient_data.current_address_city,
            current_address_state: this.patient_data.current_address_state,
            current_address_zip: this.patient_data.current_address_zip,
            current_address_country: this.patient_data.current_address_country,
            doctype: "Patient",
            allergies: this.patient_data.allergies,
          },
        };
        await CommonApi.post_data("update_patient_0018", data).then((res) => {
          if (res && res.status == 200) {
            this.$toast.success(res.message, {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.reset_patient_data();
            this.$router.push("/patient-list");
          } else {
            this.$toast.error(res.message, {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          }
        });
      }
    },
    async get_patient_details() {
      let data = {
        patient_id: this.$route.params.id,
      };
      await CommonApi.post_data("get_patient_details_0020", data).then(
        (res) => {
          if (res.data.length === 0 || res.status != 200) {
            this.$toast.error(res.message, {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.$router.push("/patient-list");
          } else {
            this.set_patient_data(res.data);
          }
        }
      );
    },
    reset_patient_data() {
      this.patient_data.title = "";
      this.patient_data.blood_group = "";
      this.patient_data.mobile_no = "";
      this.patient_data.nhs_no = "";
      this.patient_data.email = "";
      this.patient_data.ethnic_group = "";
      this.patient_data.weight = "";
      this.patient_data.name = "";
      this.patient_data.marital_status = "";
      this.patient_data.sex = "";
      this.patient_data.height = "";
      this.patient_data.date_of_birth = "";
      this.patient_data.nationality = "";
      this.patient_data.bmi = "";
      this.patient_data.current_address_line_1 = "";
      this.patient_data.current_address_line_2 = "";
      this.patient_data.current_address_city = "";
      this.patient_data.current_address_state = "";
      this.patient_data.current_address_zip = "";
      this.patient_data.current_address_country = "";
      this.patient_data.allergies = "";
      this.$refs.form.resetValidation();
    },
    validateEmail(mail) {
      // eslint-disable-next-line no-useless-escape
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (mail.match(mailformat) || mail === "") {
        this.invalidEmail = false;
        return true;
      } else {
        this.invalidEmail = true;
        return false;
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (keyCode < 48 || keyCode > 57) &&
        keyCode !== 46 &&
        keyCode !== 43 &&
        keyCode !== 45
      ) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    set_patient_data(data) {
      this.patient_data.title = data.title;
      this.patient_data.blood_group = data.blood_group;
      this.patient_data.mobile_no = data.mobile;
      this.patient_data.nhs_no = data.nhs_no;
      this.patient_data.email = data.email;
      this.patient_data.ethnic_group = data.ethnic_group;
      this.patient_data.weight = data.weight;
      this.patient_data.name = data.patients_name;
      this.patient_data.marital_status = data.marital_status;
      this.patient_data.sex = data.sex;
      this.patient_data.height = data.height;
      this.patient_data.date_of_birth = data.date_of_birth;
      this.patient_data.nationality = data.nationality;
      this.patient_data.bmi = data.bmi;
      this.patient_data.current_address_line_1 = data.current_address_line_1;
      this.patient_data.current_address_line_2 = data.current_address_line_2;
      this.patient_data.current_address_city = data.current_address_city;
      this.patient_data.current_address_state = data.current_address_state;
      this.patient_data.current_address_zip = data.current_address_zip;
      this.patient_data.current_address_country = data.current_address_country;
      this.patient_data.allergies = data.allergies;
      this.$refs.form.resetValidation();
    },
  },
  mounted() {
    this.get_patient_details();
  },
};
</script>